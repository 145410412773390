<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <b-form-group label="Title" label-for="key-input" :state="validateState('title')">
                <b-form-input id="key-input" v-model="form.title" :state="validateState('title')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="Description" label-for="description-input">
                <b-form-textarea id="description-input" v-model="form.description" rows="3" max-rows="6">
                </b-form-textarea>
            </b-form-group>

        </form>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import videosService from '../../../services/admin/videos.service';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        playlist: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
            form: {
                title: {
                    required,
                },
            },
        }
    },
    emits: ['on-close'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {},
            showModal: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            if (this.playlist != null) {
                this.form = {
                    title: this.playlist.title,
                    description: this.playlist.description,
                    id: this.playlist.id,
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                title: '',
                description: '',
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            if (this.playlist && this.playlist.id) {
                await videosService.updatePlaylist(this.playlist.id, this.form);
            } else {
                await videosService.createPlaylist(this.form);
            }
            this.$nextTick(() => {
                this.resetModal();
                this.$emit('on-close', true);
            })
        }
    },

    computed: {
        title() {
            return this.playlist != null ? 'Update Playlist' : 'Add new Playlist';
        },
    },
}
</script>

<style lang="scss" scoped></style>
<!--suppress HtmlUnknownTag -->
<template>
  <b-modal v-model="showModal" id="modal-prevent-closing" size="large" :no-close-on-backdrop="true"
           header-bg-variant="dark" header-text-variant="light"
           @hidden="onClose(false)">
    <template #modal-header>
      <h5 class="modal-header-title">{{ title }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose(false)">×
      </button>
    </template>
    <div class="container">
      <b-form class="form-content">
        <b-row class="mb-4">
          <b-col>
            <label>Month & Year:</label>
            <VueDatePicker v-model="selectedMonth" placeHolder="MM-YYYY"
                           month-picker format="MM-yyyy" :min-date="min" :max-date="max"/>
            <b-form-input v-model.trim="form.date" type="text" :state="validateForm('date')" hidden="hidden"/>
            <b-form-invalid-feedback>Month & Year is required</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <label>Notes EN (optional)</label>
            <b-form-textarea v-model.trim="form.note_en" type="text" placeholder="Notes EN" rows="6" max-rows="6">
            </b-form-textarea>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <label>Notes ID (optional):</label>
            <b-form-textarea v-model.trim="form.note_id" type="text" placeholder="Notes ID" rows="6" max-rows="6">
            </b-form-textarea>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button size="sm" class="btn-main" variant="none" @click="startDistributions">
        Start Distributions
      </b-button>
      <b-button size="sm" class="btn-outline-main" variant="none" @click="onClose(false)">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import moment from "moment"
import {useVuelidate} from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import rentalDistributionService from "@/services/admin/rentalDistribution.service"

export default {
  components: {},

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      showModal: false,
      form: {},
      selectedMonth: null,
      monthLabels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    }
  },

  methods: {
    onClose: function () {
      this.v$.$reset()
      this.form = {}
      this.selectedMonth = null
      this.$emit("on-close")
    },

    validateForm(name) {
      const {$dirty, $error} = this.v$.form[name]
      return $dirty ? !$error : null
    },

    isDataValid: async function () {
      this.v$.form.$touch()
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        window.scrollTo(0, 0)
        return false
      }
      return true
    },

    async startDistributions() {
      const isFormCorrect = await this.isDataValid()
      if (isFormCorrect) {
        const response = await rentalDistributionService.startDistributions({...this.form})
        if (response && response.message) {
          this.onClose()
        }
      }
    },
  },

  computed: {
    title() {
      return "Start Sending Rental Distributions"
    },

    min() {
      return moment().subtract(3, "months").toDate()
    },
    max() {
      return moment().subtract(1, "months").toDate()
    },
  },

  validations() {
    return {
      form: {
        date: {
          required,
        },
      },
    }
  },

  watch: {
    show(value) {
      this.showModal = value
    },

    async selectedMonth(value) {
      if (value) {
        this.form.date = moment(value).format("YYYY-MM-DD")
      } else {
        this.form.date = null
      }
    },
  }
}
</script>

<style scoped>
.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.close {
  color: #ffffff;
  opacity: .8;
  padding-bottom: 20px !important;
}

.form-content label {
  font-weight: bold;
  font-size: 16px;
}
</style>

<template>
  <div v-if="userProfile" class="goro-user-avatar-component">
    <b-dropdown ref="dropdown" variant="link" toggle-class="text-decoration-none" no-caret class="sub-menu-al-right goro-dropdown-site-menu-custom">
      <template #button-content>
        <div class="content d-flex flex-column flex-lg-row align-items-center" @click="showDropdown">
          <img v-if="avatarUrl" class="img-avatar mt-1" :src="avatarUrl"
            width="36" />
          <default-avatar v-else :width="36" :height="36"></default-avatar>
          <div class="ml-2 d-flex flex-column align-items-center align-items-lg-start justify-content-center">
            <p class="font-16 font-weight-bold main-color">{{ myProfileText }}</p>
            <p class="text-uuid">{{ $t('GORO_ID') }}: {{ uuid }}</p>
          </div>
        </div>
      </template>
      <b-dropdown-item v-if="!this.isInAdminPage && !isPartnerRole" class="sub-item" @click="openMyPortfolio">
        {{ $t('account.MY_PORTFOLIO') }}
      </b-dropdown-item>
      <b-dropdown-item v-if="!this.isInAdminPage && !isPartnerRole" class="sub-item" @click="basicInfo">
        {{ $t('account.BASIC_INFO') }}
      </b-dropdown-item>
      <b-dropdown-item v-if="!this.isInAdminPage && this.completedKYC && !isPartnerRole" class="sub-item" @click="kyc">
        {{ $t('account.KYC') }}
      </b-dropdown-item>
      <b-dropdown-item class="sub-item" @click="logout">
        {{ $t('account.LOGOUT') }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>

import roles, { isAdmin, isPartner, isUser } from "../constants/roles"
import accountService from "../services/account.service"
import { urlImage } from "../helpers/common"
import DefaultAvatar from "../components/DefaultAvatar.vue"

export default {
  components: {
    DefaultAvatar,
  },

  emits: ['close-menu'],

  methods: {
    showDropdown() {
      if (this.$refs && this.$refs.dropdown) {
        this.$refs.dropdown.show()
      }
    },
    async openMyPortfolio() {
      await this.$router.push({ name: 'assetsOverview' });
    },
    async openUserProfile() {
      if (this.isInAdminPage || this.isInAccountPage) {
        return
      }
      let name;
      if (this.isUser) {
        if (!this.$store.getters.pendingTasksCount) {
          store.dispatch("getPendingTaskCount")
        }
        if (this.$store.getters.assetsCount) {
          name = 'assetsOverview'
        } else {
          const res = await accountService.getAssetsCount()
          if (res && res.data) {
            await store.dispatch("setAssetsCount", res.data)
            name = 'assetsOverview'
          } else {
            name = 'buyProperty'
          }
        }
      } else {
        name = 'goroadmin'
      }
      if (name) {
        await this.$router.push({ name });
      }
      this.closeMenu()
    },
    async logout() {
      const isInAdminPage = this.isInAdminPage || isAdmin();
      const isInAccountPage = this.isInAccountPage || isUser();
      const isPartnerPage = isPartner()

      await store.dispatch('doLogout');
      if (isInAdminPage) {
        await this.$router.push({ name: 'loginAdmin' });
      } else if (isPartnerPage) {
        await this.$router.push({ name: 'loginPartner' });
      } else if (isInAccountPage) {
        await this.$router.push({ name: 'login' });
      }
      this.closeMenu()
    },
    async basicInfo() {
      await this.$router.push({ name: 'myAccount' });
      this.closeMenu()
    },
    async kyc() {
      await this.$router.push({ name: 'kYC' });
      this.closeMenu()
    },

    closeMenu() {
      this.$emit('close-menu')
    },

    onOver () {
      if (this.$refs && this.$refs.dropdown) {
        this.$refs.dropdown.visible = true
      }
    },
    onLeave () {
      if (this.$refs && this.$refs.dropdown) {
        this.$refs.dropdown.visible = false
      }
    }
  },
  computed: {
    avatarUrl() {
      const url = this.userProfile && this.userProfile.avatar_url
      return urlImage({ image: url })
    },
    uuid() {
      return this.userProfile && this.userProfile.uuid
    },
    userProfile() {
      return this.$store.getters.userProfile;
    },

    isInAdminPage() {
      return this.$route.path && this.$route.path.includes('goroadmin');
    },

    isInAccountPage() {
      return this.$route.path && this.$route.path.includes('account');
    },

    isPartnerRole() {
      return isPartner()
    },

    isUser() {
      return this.$store.getters.userProfile.role === roles.User;
    },

    myProfileText() {
      return this.isUser ? this.$t('account.MY_ACCOUNT') : this.$t('account.MANAGE');
    },

    completedKYC() {
			return this.userProfile && this.userProfile.selfie && this.userProfile.selfie.is_valid;
		},
  },
}
</script>
<style lang="scss" scoped>
.content {
  padding: 3px;
  margin-top: -3px;

  .text-uuid {
    font-size: 12px;
		color: gray;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
}
</style>

<template>
  <b-modal v-model="showModal" header-class="modal-header no-border" footer-class="modal-footer" hide-footer modal-class="cls-goro-custom-modal cls-pickdate-modal" @hidden="$emit('on-close')" centered="true">
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-center w-100">
          <div style="width: 30px;"></div>
          <h5 class="font-20 font-weight-bold header-title mb-0">
            {{ $t('PARTNER.PICK_DATE') }}
          </h5>
          <button class="btn-close" @click="onClose()">
            <b-icon icon="x-lg" style="color: gray;" scale="1.6"></b-icon>
          </button>
      </div>
    </template>
    <div class="popup-content d-flex flex-column align-items-center px-4 pt-0">
      <div class="d-flex flex-row justify-content-center w-100">
          <VueDatePicker 
          v-model="selectedDates"
          inline
          auto-apply
          range
          model-type="dd/MM/yyyy"
          format="dd/MM/yyyy"
          month-name-format="long"
          :enable-time-picker="false"
          :min-date="minDate"
          :max-date="maxDate"
          @range-start="onRangeStart"
          @range-end="onRangeEnd"
          style="width: 100%;">
          <template #day="{ day, date }">
            <template>
              <span class="show-date">{{ day }}</span>
            </template>
        </template>
          </VueDatePicker>
      </div>
      <p v-if="diffInDays > 31" class="font-14 color-error mt-3">
        {{ $t("PARTNER.SELECT_EXCEED_LIMIT_DAYS", { limit: limitDays }) }}
      </p>
      <div class="mt-3 cls-goro-warning-message d-flex align-items-center w-100">
          <img src="@/assets/img/info-circle.svg" alt="" width="18" height="18">
          <p class="font-12 color-black ml-3 message">
            {{ $t("PARTNER.SELECT_DATES_UP_TO", { limit: limitDays }) }}
          </p>
      </div>
      <div class="d-flex justify-content-end mt-3 w-100">
          <b-button @click="applyFilter" class="btn btn-none btn-main font-13 font-weight-normal" style="padding: 6px 25px 7px 25px;" :disabled="!enabledApply">
              {{ $t("PARTNER.APPLY") }}
          </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { DATE_TIME_FORMATS, PARTNER_PICK_DATE_VALUES } from '../../../constants/constants'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,

    },
    theme: {
      type: String,
      default: "light"
    },
    title: {
      type: String,
      default: ""
    },
  },
  emits: ['on-close', 'on-apply'],

  data() {
    return {
      limitDays: PARTNER_PICK_DATE_VALUES.DEFAULT,
      dateFormat: DATE_TIME_FORMATS.PICK_DATE,
      showModal: false,
      diffInDays: 0,
      finishSelect: true,
      selectedDates: null,
    }
  },
  watch: {
    show(value) {
      this.showModal = value
    },
    selectedDates(value) {
      this.diffInDays = moment(value[1], this.dateFormat).diff(moment(value[0], this.dateFormat), 'days', true)
      console.log('value', value, value[0], value[1], this.diffInDays)
    }
  },
  mounted() {
    this.selectedDates = [moment().subtract(PARTNER_PICK_DATE_VALUES.DEFAULT, 'days').format(this.dateFormat), moment().format(this.dateFormat)]
  },
  methods: {
      onClose() {
        this.showModal = false
        this.$emit('on-close')
      },
      onRangeStart(value) {
        this.finishSelect = false
      },
      onRangeEnd(value) {
        this.finishSelect = true
      },
      applyFilter() {
        this.$emit('on-apply', this.selectedDates)
        this.onClose()
      },
  },

  computed: {
    minDate() {
      return moment('2022-01-01').toDate()
    },

    maxDate() {
      return moment().toDate()
    },
    enabledApply() {
      return this.diffInDays <= PARTNER_PICK_DATE_VALUES.DEFAULT && this.finishSelect
    }
  }
}
</script>

<style lang="scss">
.dp__outer_menu_wrap{
      flex: 1 !important;
}
.cls-pickdate-modal{
  .dp__main{
    .dp__outer_menu_wrap{
      flex: 1 !important;
      .dp__instance_calendar{
        .dp__menu_inner{
          .dp__month_year_row{
            .dp__month_year_select{
              font-weight: 700;
            }
          }
          .dp__calendar{
            .dp__calendar_row{
              margin: 10px 0 !important;
              .dp__calendar_item{
                flex: 1;
                flex-grow: 1;
                .dp__cell_inner{
                  width: 100% !important;
                  height: 43px !important;
                  padding: 0 !important;
                  border: 0 !important;
                  span.show-date{
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 30px;
                    background-color: transparent;
                    padding: 6px;
                  }
                  &.dp__range_between{
                    span.show-date{
                      color: #000000 !important;
                      background-color: #CBECEE !important;
                    }
                  }
                  &.dp__range_start,
                  &.dp__range_end{
                    width: 43px !important;
                    height: 43px !important;
                    background-color: #CBECEE;
                    span.show-date{
                      color: #fff !important;
                      border-radius: 100%;
                      background-color: #00918E !important;
                    }
                  }
                  &.dp__range_start{
                    border-radius: 100% 0 0 100%;
                  }
                  &.dp__range_end{
                    border-radius: 0 100% 100% 0;
                  }
                  &.dp__range_start{
                    &.dp__range_end{
                      border-radius: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}
</style>
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div slot="raw-content" class="table-responsive">
          <paper-table :data="orders.data" :columns="tableColumns" :showEditAction="true"
                       @onModalEdit="onModalEdit">
            <template #theadSearch>
              <thead class="search">
              <th width="30px"></th>
              <th>
                <b-input v-model="filters.email"></b-input>
              </th>
              <th>
                <b-input v-model="filters.property_name"></b-input>
              </th>
              <th>
                <b-input v-model="filters.num_of_tokens"></b-input>
              </th>
              <th>
                <b-input v-model="filters.amount"></b-input>
              </th>
              <th>
                <b-input v-model="filters.fee"></b-input>
              </th>
              <th>
                <b-form-select v-model="filters.status" text-field="name" value-field="id">
                  <template #first>
                    <b-form-select-option :value="null">Select status</b-form-select-option>
                  </template>
                  <template v-for="status in statuses">
                    <b-form-select-option :value="status">
                      {{ status }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th></th>
              </thead>
            </template>
          </paper-table>
          <b-pagination v-if="orders.total" align="right" v-model="orders.current_page"
                        :total-rows="orders.total" :per-page="orders.per_page"
                        @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" size="md" title="Order details" centered hide-header-close
             @hide="onModalClose">

      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("ORDER.UUID") }}</p>
        <p class="value">{{ uuid }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("AUTH.NAME") }}</p>
        <p class="value">{{ name }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("AUTH.EMAIL") }}</p>
        <p class="value">{{ email }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("ORDER.PROPERTY_NAME") }}</p>
        <p class="value">{{ propertyName }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("ORDER.NUM_OF_TOKENS") }}</p>
        <p class="value">{{ numOfTokens }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.AMOUNT") }}</p>
        <p class="value">{{ amount }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.AMOUNT_FROM_BALANCE") }}</p>
        <p class="value">{{ amountFromBalance }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.FEE") }}</p>
        <p class="value">{{ fee }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.FEE_PERCENT") }}</p>
        <p class="value">{{ feePercent }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <p class="title">{{ $t("SELL_TOKEN.DESCRIPTION") }}</p>
        <p class="value">{{ description }}</p>
      </div>

      <div class="d-flex flex-row justify-content-between">
        <p class="title mr-5">{{ $t("SELL_TOKEN.STATUS") }}</p>
        <b-form-select v-model="selectedStatus" :options="isPending ? allowedChangeStatuses : statuses"
                       :disabled="!isPending">
        </b-form-select>
      </div>
      <div v-if="selectedStatus === 'APPROVED' && isPending" class="d-flex flex-row justify-content-between mt-2">
        <label class="title">{{ $t("ORDER.REPLACE_PROPERTY_UUID") }}</label>
        <textarea v-model="replacePropertyUuid" placeholder="Input replace property uuid" style="width:78%"
                  :disabled="!isPending"></textarea>
      </div>
      <div v-if="!isPending" class="d-flex flex-row justify-content-between mt-2">
        <p class="title mr-5">{{ $t("SELL_TOKEN.REASON") }}</p>
        <p class="value">{{ failedReason }}</p>
      </div>
      <div v-if="selectedStatus === 'REJECTED' && !failedReason" class="d-flex flex-row justify-content-between mt-2">
        <label class="title">{{ $t("SELL_TOKEN.REASON") }}</label>
        <textarea v-model="reason" placeholder="Input reason" style="width:78%"
                  :disabled="!isPending"></textarea>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button :disabled="selectedStatus === statuses.REJECTED && reason === ''"
                  v-if="isPending && selectedStatus !== statuses.PENDING"
                  size="sm" variant="success" @click="onUpdate">
          Update
        </b-button>
        <b-button size="sm" variant="primary" @click="ok()">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import { STATUS } from "../../constants/constants"
import { numberWithCommas } from "../../helpers/common"
import ordersService from "../../services/orders.service"

const tableColumns = ["Id", "Email", "Property_Name", "Num_Of_Tokens", "Amount", "Fee", "Status", "created_at"]

export default {
  components: {
    PaperTable,
    STATUS,
  },
  data () {
    return {
      orders: {},
      tableColumns: [...tableColumns],
      filters: {
        email: "",
        property_name: "",
        num_of_tokens: "",
        amount: "",
        fee: "",
        status: "",
      },
      showModal: false,
      order: null,
      selectedStatus: "",
      statuses: [STATUS.PENDING, STATUS.APPROVED, STATUS.REJECTED],
      allowedChangeStatuses: [STATUS.PENDING, STATUS.APPROVED, STATUS.REJECTED],
      replacePropertyUuid: null,
      reason: "",
      needUpdate: false,
    }
  },
  async mounted () {
    await this.getOrders(1)
  },
  watch: {
    "filters.email" () {
      this.searchTimeOut(1)
    },
    "filters.property_name" () {
      this.searchTimeOut(1)
    },
    "filters.num_of_tokens" () {
      this.searchTimeOut(1)
    },
    "filters.received_amount" () {
      this.searchTimeOut(1)
    },
    "filters.fee" () {
      this.searchTimeOut(1)
    },
    "filters.status" () {
      this.searchTimeOut(1)
    }
  },
  methods: {

    async getOrders (page) {
      const filters = {
        ...this.filters,
        page,
      }
      const result = await ordersService.getOrders(filters)
      if (result && result.data) {
        this.orders = result
      }
    },

    async onChangePage (page) {
      await this.getOrders(page)
    },

    searchTimeOut (page) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        await this.getOrders(page)
      }, 400)
    },

    onModalClose () {
      this.showModal = false
      if (this.needUpdate) {
        this.getOrders(this.orders.current_page)
      }
    },

    onModalDelete (data) {
      this.modalDelete = data
    },

    onModalEdit (data) {
      if (data) {
        this.order = this.orders.data.find((e) => e.id === data)
        this.selectedStatus = this.order.status
        this.showModal = true
        this.needUpdate = false
      }
    },

    async onUpdate () {
      if (this.selectedStatus === "APPROVED") {
        const res = await ordersService.approveOrder({
          order_uuid: this.order.uuid,
          replace_property_uuid: this.replacePropertyUuid,
        })
        if (res && res.order) {
          this.order = res.order
          this.needUpdate = true
          this.selectedStatus = res.order.status
        }
      } else if (this.selectedStatus === STATUS.REJECTED && this.reason !== "") {
        const res = await ordersService.rejectOrder({
          order_uuid: this.order.uuid,
          reason: this.reason,
        })
        if (res && res.order) {
          this.order = res.order
          this.needUpdate = true
          this.selectedStatus = res.order.status
        }
      }
    },
  },

  computed: {
    isPending () {
      return this.order && this.order.status === STATUS.PENDING
    },

    uuid () {
      return this.order && this.order.uuid
    },

    name () {
      return this.order && this.order.name
    },

    email () {
      return this.order && this.order.email
    },

    propertyName () {
      return this.order && this.order.property_name
    },

    numOfTokens () {
      return this.order && numberWithCommas(this.order.num_of_tokens)
    },

    amount () {
      return this.order && numberWithCommas(this.order.amount)
    },

    amountFromBalance () {
      return this.order && numberWithCommas(this.order.amount_from_balance)
    },

    fee () {
      return this.order && numberWithCommas(this.order.fee)
    },

    feePercent () {
      return this.order && numberWithCommas(this.order.fee_percent) + "%"
    },

    description () {
      return this.order && this.order.description
    },

    failedReason () {
      return this.order && this.order.reason
    },
  },
}
</script>
<style>

</style>


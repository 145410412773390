import serverApi from "../../utils/serverApi"

const videosService = {
  getPlaylists: (data) => serverApi({uri: 'admin/video-playlists', method: 'GET', data}),

  createPlaylist: (data) => serverApi({uri: 'admin/video-playlists', method: 'POST', data}),

  updatePlaylist: (id, data) => serverApi({uri: `admin/video-playlists/${id}`, method: 'PUT', data}),

  deletePlaylist: (id) => serverApi({uri: `admin/video-playlists/${id}`, method: 'DELETE'}),

  getVideos: (data) => serverApi({uri: 'admin/videos', method: 'GET', data}),

  createVideo: (data) => serverApi({uri: 'admin/videos', method: 'POST', data}),

  updateVideo: (id, data) => serverApi({uri: `admin/videos/${id}`, method: 'PUT', data}),

  deleteVideo: (id) => serverApi({uri: `admin/videos/${id}`, method: 'DELETE'}),
};

export default videosService;

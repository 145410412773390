<template>
  <b-modal
    v-model="showModal"
    size="lg"
    modal-class="cls-goro-custom-modal cls-custom-transaction-detail-modal"
    body-class="pt-0"
    header-class="modal-header no-border pb-0"
    footer-class="modal-footer"
    @hidden="$emit('on-close')"
    hide-footer
  >
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div style="width: 30px"></div>
        <h5 class="font-20 font-weight-bold header-title mb-0">
        </h5>
        <button class="btn-close" @click="onClose()">
          <b-icon icon="x-lg" style="color: gray" scale="1.6"></b-icon>
        </button>
      </div>
    </template>
    <template #default="{ hide }">
      <div v-if="transaction" class="container cls-transaction-details-container">
        <div class="d-flex flex-column text-center">
          <div v-if="!hasProperty" class="transaction-icon-bg d-flex justify-content-center align-items-center">
            <img src="../../../assets/img/transaction.svg" alt=""/>
          </div>
          <h2 class="font-26 font-weight-bold cls-type-heading mt-0 mb-2">
            {{ type }}
          </h2>
          <div class="d-flex flex-row justify-content-center cls-transaction-date-time">
            <img class="clock-icon" src="../../../assets/img/clock.svg" alt=""/>
            <p class="font-13 font-normal ml-2">
              {{ date }} <span class="time pl-1 ml-1">{{ time }}</span>
            </p>
          </div>
          <div v-if="hasProperty" class="property-info mt-3">
            <b-img class="property-image" :src="propertyImage" alt=""/>
            <p class="property-name d-flex flex-row">{{ propertyName }}</p>
            <p class="transaction-status status-position" :style="{ 'background-color': statusBackground }">{{ status }}</p>
          </div>
          <div class="cls-transaction-content">
            <h3 class="text-left mt-4 mb-2 font-20 font-weight-bold">
              {{ $t('PARTNER.TRANSACTION.TRANSACTION_DETAILS') }}
            </h3>
            <div v-if="hasProperty" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.STATUS') }}</p>
              <p class="transaction-status" :style="{ 'background-color': statusBackground }">{{ status }}</p>
            </div>
            <div v-if="!hasProperty" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.STATUS') }}</p>
              <p class="transaction-status" :style="{ 'background-color': statusBackground }">{{ status }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.UUID') }}</p>
              <div class="d-flex flex-row align-items-center">
                <p class="value">{{ transaction.uuid }}</p>
                <img class="copy-icon ml-1" src="../../../assets/img/copy.png" @click="copyTransactionUUID" alt=""/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.EXTERNAL_ID') }}</p>
              <div class="d-flex flex-row align-items-center">
                <p class="value">{{ externalID }}</p>
                <img class="copy-icon ml-1" src="../../../assets/img/copy.png" @click="copyTransactionId" alt=""/>
              </div>
            </div>
            <div v-if="hasBlockchainTransaction" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.BLOCKCHAIN_TRANSACTION_ID') }}</p>
              <div class="d-flex flex-row align-items-center">
                <a v-if="blockchainTransactionLink" class="link"
                  :href="blockchainTransactionLink" target="_blank">
                    {{ blockchainTransactionExternalId }}
                </a>
                <p v-else class="value">
                  {{ blockchainTransactionExternalId }}
                </p>
                <img class="copy-icon ml-1" src="../../../assets/img/copy.png" @click="copyBlockTransactionID" alt=""/>
              </div>
            </div>
            <div v-if="isOrderToken" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.ORDER_UUID') }}</p>
              <router-link class="link" :to="{ name: 'orderDetail', query: { order_uuid: orderUuid } }">
                {{ orderUuid }}
              </router-link>
            </div>
            <div v-if="isSellToken" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.SELL_REQUEST_UUID') }}</p>
              <p class="value">{{ sellTokenRequestUuid }}</p>
            </div>
            <div v-if="isBuyToken || isOrderToken || isBuyTokenUsingVirtualBalance || isSellToken || isSwapToken"
                class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('PARTNER.TRANSACTION.TOKENS') }}</p>
              <p class="value">{{ tokens }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ isWithdrawal ? $t('WITHDRAWALS.AMOUNT') : $t('TRANSACTION.VALUE') }}</p>
              <p class="value" id="price-value">{{ exchangeValue(value) }}</p>
              <CurrencyTooltip tooltipId="price-value" :value="value"></CurrencyTooltip>
            </div>
            <div v-if="isWithdrawal || isSellToken" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('WITHDRAWALS.FEE') }}</p>
              <p class="value" id="fee">{{ exchangeValue(fee) }}</p>
              <CurrencyTooltip tooltipId="fee" :value="fee"></CurrencyTooltip>
            </div>
            <div v-if="isSellToken" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('SELL_TOKEN.RECEIVED_AMOUNT') }}</p>
              <p class="value" id="sell-receive-amount">{{ exchangeValue(value - fee) }}</p>
              <CurrencyTooltip tooltipId="sell-receive-amount" :value="value - fee"></CurrencyTooltip>
            </div>
            <div class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ $t('TRANSACTION.NOTE') }}</p>
              <p class="value">{{ note }}</p>
            </div>
            <div v-if="isBuyTokenUsingVirtualBalance" class="d-flex flex-row justify-content-between transaction-info-item">
              <p class="title">{{ isExpired ? $t('VIRTUAL_BALANCE.EXPIRED_ON') : $t('VIRTUAL_BALANCE.EXPIRES_ON') }}</p>
              <p class="value">{{ expirationTime }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { PaperTable } from "../../../components";
import {getTransactionStatus, exchange, notify, urlImage, obfuscateBlockchainInfo, formatCurrency} from '@/helpers/common';
import {BLOCKCHAIN, INDO, TransactionType} from "../../../constants/constants";
import CurrencyTooltip from "../../../components/CurrencyTooltip.vue"

export default {
  components: {
    CurrencyTooltip,
  },
  props: {
    title: {
      type: String,
      default: "Transaction detail",
    },
    show: {
      type: Boolean,
      default: false,
    },
    transaction: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      property: null,
      showModal: false,
    };
  },

  emits: ["on-close"],

  watch: {
    async transaction(transaction) {
      if (transaction && transaction.property) {
        this.property = transaction.property
      }
      
    },

    show(value) {
      this.showModal = value;
    },
  },

  methods: {
    exchangeValue(value) {
      return exchange(value, 100, false, INDO.CURRENCY)
    },

    copyTransactionId() {
      navigator.clipboard.writeText(this.transaction.external_id);
      notify({text: this.$t('common.COPIED')});
    },

    copyTransactionUUID() {
      navigator.clipboard.writeText(this.transaction.uuid);
      notify({text: this.$t('common.COPIED')});
    },

    copyBlockTransactionID() {
      navigator.clipboard.writeText(this.blockchainTransactionExternalId);
      notify({text: this.$t('common.COPIED')});
    },

    onClose: function () {
      this.$emit("on-close");
    },

    getImageUrl(image) {
      return urlImage(image);
    },

    getPercent(tokens) {
      return (
        Math.round(((tokens * 100) / this.property.total_tokens) * 10) / 10
      );
    },

    async openPropertyDetails() {
      const route = this.$router.resolve({
        name: "propertyDetail",
        params: { uuid: this.property.uuid },
      });
      window.open(route.href, "_blank");
    },

    searchTimeOut(page = 1) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.getHolders(this.property.id, page);
      }, 400);
    },

    async onChangePage(page) {
      await this.getHolders(this.property.id, page);
    },

    getFormattedCurrency(value) {
      return formatCurrency(value);
    },
  },

  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    hasProperty() {
      return this.transaction && this.transaction.property;
    },

    propertyImage() {
      if (!this.property) {
        return require("../../../assets/img/default_avatar_purchase.png")
      }
      return urlImage(this.property.images[0]);
    },

    propertyName() {
      return this.property && `${this.property.name} - ${this.property.category.name}` || '';
    },

    type() {
      const type = this.transaction && this.transaction.type || ''
      return this.$t(`TRANSACTIONS_TYPES.${type}`)
    },

    tokens() {
      return this.transaction && this.transaction.num_of_tokens || 0;
    },

    value() {
      return this.transaction && this.transaction.amount + this.transaction.amount_from_balance + this.transaction.fee || 0
    },

    fee() {
      if (this.transaction) {
        if (this.transaction.withdrawal) {
          return this.transaction.withdrawal.fee
        } else if (this.transaction.sell_token_request) {
          return this.transaction.sell_token_request.fee
        }
      }
      return 0
    },

    note() {
      return this.transaction && this.transaction.note || '';
    },

    date() {
      if (!this.transaction) {
        return ""
      }
      return moment(this.transaction.created_at).format('DD MMM YY');
    },

    time() {
      if (!this.transaction) {
        return ""
      }
      return moment(this.transaction.created_at).format('HH:mm');
    },

    externalID() {
      if (this.transaction) {
        if (this.transaction.external_id) {
          return this.transaction.external_id
        } else if (this.transaction.payment && this.transaction.payment.external_id) {
          return this.transaction.payment.external_id
        } else if (this.transaction.withdrawal && this.transaction.withdrawal.external_id) {
          return this.transaction.withdrawal.external_id
        }
      }
      
      return ''
    },

    hasBlockchainTransaction() {
      return this.transaction && this.transaction.blockchain_transaction;
    },

    blockchainTransactionExternalId() {
      if (this.transaction && 
        this.transaction.blockchain_transaction && 
        this.transaction.blockchain_transaction.external_id) {
        return obfuscateBlockchainInfo(this.transaction.blockchain_transaction.external_id) || '-'
      }
      return '-'
    },

    blockchainTransactionLink() {
      if (this.blockchainTransactionExternalId) {
        return BLOCKCHAIN.POLYGON_SCAN_TRANSACTION_URL + this.blockchainTransactionExternalId
      }
      return null
    },

    orderUuid() {
      return this.transaction && this.transaction.order && this.transaction.order.uuid;
    },

    sellTokenRequestUuid() {
      return this.transaction && 
        this.transaction.sell_token_request && 
        this.transaction.sell_token_request.uuid
    },

    isBuyToken() {
      return this.transaction && this.transaction.type === TransactionType.BUY_TOKEN;
    },

    isBuyTokenUsingVirtualBalance() {
      return this.transaction && this.transaction.type === TransactionType.BUY_TOKEN_VIRTUAL_BALANCE;
    },

    isOrderToken() {
      return this.transaction && this.transaction.type === TransactionType.ORDER_TOKEN;
    },

    isSellToken() {
      return this.transaction && 
        this.transaction.sell_token_request && 
        this.transaction.type === TransactionType.SELL_TOKEN
    },

    isSwapToken() {
      if (this.transaction && this.transaction.swap_token_transaction) {
        if ((this.transaction.type === TransactionType.BUY_TOKEN) || (this.transaction.type === TransactionType.SELL_TOKEN)) {
          return true
        }
      }
      return false
    },

    status() {
      if (!this.transaction) {
        return ''
      }
      return this.$t(`TRANSACTIONS_STATUS.${getTransactionStatus(this.transaction)}`);
    },

    statusBackground() {
      if (this.transaction) {
        const status = getTransactionStatus(this.transaction)
        if (status === 'FAILED' || status === 'EXPIRED' || status === 'REJECTED' || status === 'CANCELLED') {
          return '#bf2300'
        } else if (status === 'PAID' || status === 'COMPLETED' || status === 'RECEIVED' || status === "SWAPPED") {
          return '#81A431'
        } else if (status === 'APPROVED') {
          if (this.transaction.type === TransactionType.WITHDRAWAL) {
            return '#CE7F2D'
          } else {
            return '#81A431'
          }
        } else if (status === "REFUNDED") {
          return '#474747'
        }
      }
      return '#CE7F2D'
    },

    isWithdrawal() {
      return this.transaction && this.transaction.type === TransactionType.WITHDRAWAL;
    },

    expirationTime() {
      return this.transaction && moment(this.transaction.expiration_time).format('DD/MM/YYYY HH:mm') || '';
    },

    isExpired() {
      return moment() > moment(this.transaction.expiration_time);
    },
  },
};
</script>

<style lang="scss" scoped>
.cls-transaction-details-container {
  *{
    font-family: "NewAcuminVariableConcept", Helvetica, sans-serif, serif;
  }
  .transaction-status {
    color: white;
    padding: 3px 10px 3px 10px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }

  .cls-transaction-date-time{
    color: #AAAAAA;
    font-weight: 382;
    line-height: 14.4px;
    text-align: left;
    .time{
      border-left: 1px solid #AAAAAA;
    }
  }

  .property-info {
    position: relative;
    width: 380px;
    height: 200px;
    align-self: center;

    .property-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
      background: var(--primary-background-color);
    }

    .property-name {
      background-image: linear-gradient(to right, #013D3D, #00666671);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0 0 16px 16px;
      padding: 11px 18px 11px 18px;
      font-weight: bold;
      font-size: 14px;
      color: #fff;
    }

    .status-position {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  .cls-type-heading{
    color: #3F3F3F;
    font-weight: 600;
  }

  .cls-transaction-content{
    h3{
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #3F3F3F;
    }
    .transaction-info-item {
      margin-bottom: 7px;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      color: #3F3F3F;

      .title {
        padding-right: 8px;
        color: #7C7C7C;
        white-space: nowrap;
      }

      .value {
        text-align: end;
      }

      .copy-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
        margin-bottom: 1px;
      }

      .link {
        color: var(--text-color);
        text-decoration: underline;
      }
    }
  }

  .clock-icon {
    width: 14px;
    height: 14px;
    margin-top: 2px
  }

  
}
</style>

import store from "../store/store"

function partnerHasPermission(permission) {
  const partnerPermissions = store.getters.partnerPermissions;
  if (!partnerPermissions) {
    return false;
  }
  return partnerPermissions.includes(permission);
}

export {
  partnerHasPermission,
};

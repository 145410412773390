import authService from "../services/auth.service"
import commonService from "../services/common.service"
import notificationsService from "../services/notifications.service"
import accountService from "../services/account.service"
import guestService from "../services/guest.service"
import locationsService from "../services/locations.service"
import { isFullyActive, isLoggedIn } from "../constants/userStatus"
import i18n from "../i18n"
import { isAdmin, isPartner, isUser } from "../constants/roles"
import { getDeviceUUID, clearStorageActionKeys } from "../helpers/common"
import adminUsersService from "../services/admin/adminUsers.service"
import partnerService from "../services/partner/partner.service"
import { gtmTrackEvent } from "../helpers/gtm"
import { GTM_EVENT_NAMES } from "../constants/gtm"
import { FOREIGNER, INDO, STORAGE_ACTION_KEYS } from "../constants/constants"
import videoService from "../services/videos.service"

const updateLocale = (user) => {
  if (user.preferred_locale && user.preferred_locale !== localStorage.getItem("preferred_locale")) {
    localStorage.setItem("preferred_locale", user.preferred_locale)
  }
  if (user.phone && user.preferred_locale) {
    let locale = i18n.global.locale.value
    if (user.phone.startsWith(INDO.CALLING_CODE) && locale !== FOREIGNER.LOCALE && locale !== user.preferred_locale) {
      i18n.global.locale.value = user.preferred_locale
    }
  }
}

export default {
  async refreshUserProfile({ commit }) {
    if (isLoggedIn()) {
      const res = await authService.getUserProfile(false);
      if (res && res.data) {
        commit("setUserProfile", res.data);
        gtmTrackEvent({
          event: GTM_EVENT_NAMES.SET_AMPLITUDE_USER_ID,
          value: res.data.uuid,
          amplitude_user_id: res.data.uuid,
        });
        updateLocale(res.data);
      }
    }
  },
  setUserProfile({ commit }, currentUser) {
    commit("setUserProfile", currentUser);
    updateLocale(currentUser);
  },
  setIsLoading({ commit }, loading) {
    commit("setIsLoading", loading);
  },
  async doLogout({ commit }) {
    try{
      await authService.logout();
    } catch (e) {
      // Nothing
    }
    gtmTrackEvent({
      event: GTM_EVENT_NAMES.USER_LOGOUT,
      value: null,
      amplitude_user_id: null,
    });
    localStorage.removeItem("Authorization");
    clearStorageActionKeys()
    commit("setUserProfile", null);
    commit("setReferralCode", null);
    commit("setUnreadCount", 0);
    commit("setAssetsCount", 0);
    commit("setPendingTasksCount", 0);
    gtmTrackEvent({
      event: GTM_EVENT_NAMES.SET_AMPLITUDE_USER_ID,
      value: null,
      amplitude_user_id: null,
    });
  },
  async refreshConfig({ commit }) {
    const res = await commonService.getConfigs();
    if (res && res.data) {
      commit("setConfigs", res.data);
    }
  },
  async refreshSanctumCsrfCookie() {
    await commonService.getSanctumCsrfCookie();
  },
  async getUnreadNotificationCount({ commit }) {
    if (isUser()) {
      const data = await notificationsService.unreadCount();
      if (data) {
        commit("setUnreadCount", data.unread || 0);
      }      
    }
  },
  async getAvailableBanks({ commit }) {
    const res = await commonService.getAvailableBanks();
    if (res && res.data) {
      const data = res.data.filter((e) => e.can_disburse);
      commit("setAvailableBanks", data);
    }
  },
  async getReferralCode({ commit }) {
    if (isFullyActive()) {
      const res = await accountService.getReferralCode();
      if (res && res.data) {
        commit("setReferralCode", res.data);
      }
    }
  },
  setReferralCode({ commit }, referralCode) {
    commit("setReferralCode", referralCode);
  },

  setAssetsCount({ commit }, count) {
    commit("setAssetsCount", count);
  },

  setPendingTasksCount({ commit }, count) {
    commit("setPendingTasksCount", count);
  },

  async getInitialBalance({ commit }) {
    const res = await guestService.getInitialBalance(getDeviceUUID());
    if (res && res.data >= 0) {
      commit("setInitialBalance", res.data);
    }
  },

  async getExchangeRates({ commit }) {
    const res = await commonService.getExchangeRates();
    if (res && res.data) {
      commit("setExchangeRates", res.data);
    }
  },

  async getPendingTaskCount({ commit }) {
    if (isUser()) {
      const pendingTaskCount = await accountService.getPendingTasksCount();
      if (pendingTaskCount && pendingTaskCount.data >= 0) {
        commit("setPendingTasksCount", pendingTaskCount.data || 0);
      }
    }
  },

  async getGeoLocation ({ commit }) {
    const geolocation = await locationsService.getGeoLocation()
    if (geolocation && geolocation.data) {
      commit("setGeoLocation", geolocation.data)
    }
  },

  async setStayAtCurrentLocation ({ commit }, stayAtCurrentLocation) {
    commit("setStayAtCurrentLocation", stayAtCurrentLocation)
  },

  async setSwitchToNewLocation ({ commit }, switchToNewLocation) {
    commit("setSwitchToNewLocation", switchToNewLocation)
  },

  setSocialLoginData({ commit }, data) {
    commit("setSocialLoginData", data);
  },

  async getAdminPermissions({ commit }) {
    if (isAdmin()) {
      const res = await adminUsersService.getAdminPermissions();
      if (res && res.data) {
        commit("setAdminPermissions", res.data);
      }
    }
  },

  setRequired2FA({ commit }, data) {
    commit("setRequired2FA", data);
  },

  async getPartnerPermissions({ commit }) {
    if (isPartner()) {
      const res = await partnerService.getPartnerPermissions();
      if (res && res.data) {
        commit("setPartnerPermissions", res.data);
      }
    }
  },

  async getGoLearnPlaylists({ commit }) {
    if (isUser()) {
      const res = await videoService.getPlaylists();
      if (res && res.data) {
        commit("setGoLearnPlaylists", res.data);
      }
    }
  },
};

<template>
    <div class="mt-2 p-2">
        <b-row align-v="center">
            <b-col cols="11">
                <b-row align-v="center">
                    <b-col cols="4">
                        <b-form-input v-model="object.key" placeholder="Key"
                            :state="validateLinks(index, 'key')"></b-form-input>
                    </b-col>
                    <b-col cols="8" class="mt-1">
                        <b-form-input v-model="object.value" placeholder="Value"
                            :state="validateLinks(index, 'value')"></b-form-input>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="1" class="text-right">
                <button id="removeObject" size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                    @click="removeObject(index)">x</button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    props: {
        object: { type: Object, required: true },
        index: { type: Number, required: true },
    },
    setup() {
        return { v$: useVuelidate() }
    },
    methods: {
        validateLinks(index, field) {
            const { $dirty, $error } = this.v$.object[field]
            return $dirty ? !$error : null
        },
        removeObject(index) {
            this.$emit('removeObject', index)
        },
    },
    validations: {
        object: {
            key: { required },
            value: { required },
        }
    }
}
</script>
import serverApi from "../utils/serverApi"

const accountService = {
    updateInvestInfo: (body) => serverApi({ uri: 'account/update-invest-info', method: 'PUT', data: body }),

    uploadCard: (body) => serverApi({ uri: 'account/upload-card', method: 'POST', data: body, isLoading: false }),

    updateCard: (body) => serverApi({ uri: 'account/update-card', method: 'PUT', data: body }),

    uploadSelfie: (body) => serverApi({ uri: 'account/upload-selfie', method: 'POST', data: body, isLoading: false }),

    checkCFTPEP: () => serverApi({ uri: 'account/check-cft-pep', method: 'POST', data: {}, isLoading: false }),

    getAssets: () => serverApi({ uri: 'account/assets', method: 'GET' }),

    getReferralCode: (isLoading = false) => serverApi({ uri: 'account/referral-code', method: 'GET', isLoading: isLoading }),

    getReferrals: () => serverApi({ uri: 'account/referrals', method: 'GET' }),

    uploadPortfolioOverview: (body) => serverApi({
      uri: "account/referral-upload-portfolio-overview",
      method: "POST",
      data: body
    }),

    getTransactions: (data) =>  serverApi({ uri: 'account/transactions', method: 'GET', data }),

    getPendingTasks: (data) =>  serverApi({ uri: 'account/tasks-pending', method: 'GET' , data }),

    getCompletedTasks: (data) =>  serverApi({ uri: 'account/tasks-completed', method: 'GET', data }),

    getPendingTasksCount: () =>  serverApi({ uri: 'account/tasks-pending-count', method: 'GET', isLoading: false }),

    getRentalIncomesFilter: () =>  serverApi({ uri: 'account/rental-incomes-filter', method: 'GET' }),

    getRentalIncomes: (data) =>  serverApi({ uri: 'account/rental-incomes', method: 'GET', data }),

    getRentalIncomeDetailsReport: (data) => serverApi({
      uri: "account/rental-income-details-report", method: "GET", data
    }),

    skipKYC: () =>  serverApi({ uri: 'account/skip-kyc', method: 'PUT' }),

    getAssetsCount: () => serverApi({ uri: 'account/assets-count', method: 'GET' }),

    getVirtualRentalIncomes: () =>  serverApi({ uri: 'account/virtual-rental-incomes', method: 'GET' }),

    getVirtualRentalIncomeDetailsReport: (data) => serverApi({
      uri: "account/virtual-rental-income-details-report", method: "GET", data
    }),

    getTransactionBySecondaryId: (secondaryId) => serverApi({ uri: `account/transactions/${secondaryId}`, method: 'GET' }),

    updatePreferredCurrency: (body) => serverApi({ uri: `account/update-preferred-currency`, method: 'PUT', data: body }),

    updateAssetsSettings: (body) => serverApi({ uri: `account/update-assets-settings`, method: 'PUT', data: body }),

    getOwningTokens: (isLoading = true) => serverApi({ uri: 'account/owning-tokens', method: 'GET', isLoading: isLoading }),

    getLockedTokens: (property_uuid) => serverApi({ uri: `account/locked-tokens/${property_uuid}`, method: "GET" }),

    getTokensStatus: (property_uuid) => serverApi({ uri: `account/tokens-status/${property_uuid}`, method: "GET" }),

    changePassword: (body) => serverApi({ uri: `account/change-password`, method: 'PUT', data: body }),

    getOwningTokensOfProperty: (data) => serverApi({ uri: 'account/owning-tokens-of-property', method: 'GET', data }),

    canChangeUsername: () => serverApi({ uri: 'account/username/can-change', method: 'GET' }),

    changeUsername: (body) => serverApi({ uri: `account/username`, method: 'PUT', data: body, isManualErrorHandling: true }),

    canUseUsername: (body) => serverApi({ uri: `account/username/can-use`, method: 'POST', data: body, isManualErrorHandling: true, isNotify: false }),

    getTopReferrers: (data, isLoading = true) => serverApi({ uri: 'account/top-referrers', method: 'GET', data, isLoading: isLoading }),
};

export default accountService;

export default {

  SOCIAL_MEDIA: {
    FACEBOOK: "https://www.facebook.com/goroindonesia/",
    LINKEDIN: "https://www.linkedin.com/company/goroindonesia/",
    INSTAGRAM: "https://www.instagram.com/goroindonesia/",
    TIKTOK: "https://www.tiktok.com/@goroindonesia/",
    CAREERS: "https://www.linkedin.com/company/goroindonesia/jobs/",
    YOUTUBE: "https://www.youtube.com/@goroindonesia",
  },

  REGISTERED_ON: {
    KOMINFO: "https://pse.kominfo.go.id/tdpse-detail/11835",
    TSI_KAN: "https://layanan.kan.or.id/sertifikat/9692",
  },

  MAIL_TO: {
    SELL_MY_PROPERTY: "https://jual-bu.com/",
    CONTACT_US: "mailto:contact@goro.id",
    CUSTOMER_SUPPORT: "mailto:hello@goro.id",
    RECRUITMENT: "mailto:recruitment@goro.id"
  },

  WHATSAPP: {
    COMPLAIN_SERVICE: "https://wa.me/6285311111010",
  },

  WHATSAPP_SUPPORTS: {
    "id": "https://wa.me/+6281290904676",
    "en": "https://wa.me/+6281290904676",
    "en-UK": "https://wa.me/+6285751925122",
  },

  PHONE_SUPPORTS: {
    "id": "+6281290904676",
    "en": "+6281290904676",
    "en-UK": "+6285751925122",
  },

  STORE: {
    GOOGLE_PLAY: "https://play.google.com/store/apps/details?id=com.goro.id",
    APP_STORE: "https://apps.apple.com/app/goro/id6457259937",
  },

  OFFICES: {
    GORO_BALI_OFFICE: "https://maps.app.goo.gl/AZxoPuNi12NbJFnS7?g_st=ic",
    GORO_JAKARTA_OFFICE: "https://maps.app.goo.gl/GyXqD8Gc9pFjF9ZWA"
  },

  CONTACT_EMAIL: "contact@goro.id",
  CUSTOMER_EMAIL: "hello@goro.id",
  FAQ: "https://goro.tawk.help/",
  TELEGRAMS: {
    DEFAULT: "https://t.me/goroindonesia"
  },
  SANDBOX: "https://www.ojk.go.id/id/Fungsi-Utama/ITSK/Regulatory-Sandbox/Documents/Daftar%20Peserta%20Sandbox%20Posisi%20November%202024.pdf"
}

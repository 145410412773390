<template>
    <b-modal v-model="showModal" id="full-screen-modal" ref="modal" size="huge" header-bg-variant="dark"
        header-text-variant="light" header-class="modal-header" footer-class="modal-footer" body-class="modal-body-full"
        :title="title" @show="updateModal" @hidden="$emit('on-close')" @ok="handleOk">

        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row align-h="center">
                <b-col cols="10">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group label="Title" label-for="title-input" :state="validateState('title')">
                                <b-form-input id="title-input" v-model="form.title" :state="validateState('title')"
                                    required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Description" label-for="description-input">
                                <b-form-textarea id="description-input" v-model="form.description" rows="15"
                                    max-rows="15">
                                </b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group label="Video" label-for="video-link-input" :state="validateState('url')">
                                <b-form-input id="video-link-input" v-model="form.url" :state="validateState('url')"
                                    required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group :label="`Video Duration In Seconds (${displayDuration})`"
                                label-for="video-duration-input" :state="validateState('duration')">
                                <b-form-input type="number" id="video-duration-input" v-model="form.duration"
                                    :state="validateState('duration')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Weight" label-for="weight-input" :state="validateState('weight')">
                                <b-form-input id="weight-input" type="number" v-model="form.weight"
                                    :state="validateState('weight')" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Language" label-for="lang-input" :state="validateState('lang')">
                                <b-form-select id="lang-input" v-model="form.lang" text-field="name"
                                    :state="validateState('lang')" required>
                                    <b-form-select-option :value="null" disabled>Select Language</b-form-select-option>
                                    <b-form-select-option value="en">English</b-form-select-option>
                                    <b-form-select-option value="id">Indonesian</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label="Playlist" label-for="playlist-input"
                                :state="validateState('video_playlist_id')">
                                <b-form-select id="playlist-input" v-model="form.video_playlist_id" text-field="name"
                                    :state="validateState('video_playlist_id')" required>
                                    <b-form-select-option :value="null" disabled>Select Playlist</b-form-select-option>
                                    <b-form-select-option v-for="playlist in playlists" :value="playlist.id">{{
                                        playlist.title }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label="Featured Content" label-for="featured-input">
                                <b-form-checkbox class="custom-checkbox-control" v-model="form.is_featured">
                                    Show in Featured Content
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col cols="10" lg="11">
                            <div>Glossary</div>
                        </b-col>
                        <b-col cols="2" lg="1" class="text-right">
                            <button size="sm" type="button" class="btn-outline-main pl-4 pr-4 mr-2"
                                @click="addGlossary">+</button>
                        </b-col>
                    </b-row>
                    <KeyValueInput v-for="(item, index) in form.glossary" :index="index" :object="item" @removeObject="removeObject" />
                    <p class="my-2">Thumbnail</p>
                    <VueFileAgent ref="vueFileAgent" v-model="images" v-model:rawModelValue="images" :theme="'grid'"
                        :multiple="false" :meta="false" :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1"
                        :helpText="'Choose image file'" :errorText="{
                            type: 'Invalid file type. Only images are allowed',
                            size: 'Files should not exceed 3MB in size',
                        }" @beforedelete="onBeforeDeleteImage($event)" @select="filesSelectedImage($event)" required>
                    </VueFileAgent>
                </b-col>
            </b-row>
        </form>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getBase64 } from "../../../helpers/common"
import videosService from '../../../services/admin/videos.service';
import { urlImage, formattedDuration } from '../../../helpers/common';
import KeyValueInput from '../../../components/KeyValueInput.vue';

export default {
    components: {
        KeyValueInput,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        video: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
            form: {
                title: {
                    required,
                },
                url: {
                    required,
                },
                weight: {
                    required
                },
                lang: {
                    required
                },
                video_playlist_id: {
                    required,
                },
                is_featured: {
                    required,
                },
                duration: {
                    required,
                },
            },
        }
    },
    emits: ['on-close'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {},
            images: [],
            showModal: false,
            playlists: [],
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        async getPlaylists() {
            const result = await videosService.getPlaylists({
                page: 1,
                per_page: 1000,
            });
            if (result && result.data) {
                this.playlists = result.data;
            }
        },
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            if (this.video != null) {
                this.form = {
                    id: this.video.id,
                    title: this.video.title,
                    description: this.video.description,
                    url: this.video.url,
                    weight: this.video.weight,
                    lang: this.video.lang,
                    video_playlist_id: this.video.video_playlist_id,
                    is_featured: this.video.is_featured,
                    duration: this.video.duration,
                    glossary: this.video.glossary || [],
                };
                if (this.video.thumb) {
                    const name = this.video.thumb.split("/").pop()
                    const type = this.video.thumb.split(".").pop()
                    const url = urlImage({ image: this.video.thumb })
                    const images = [
                        {
                            uploaded: true,
                            name: name,
                            url: url,
                            path: url,
                            size: 0,
                            type: `image/${type}`,
                        }
                    ]
                    this.images = images
                } else {
                    this.images = []
                }
            } else {
                this.resetModal();
            }
            if (!this.playlists.length) {
                this.getPlaylists()
            }
        },

        resetModal() {
            this.form = {
                title: '',
                description: '',
                url: '',
                weight: 0,
                lang: 'en',
                is_featured: false,
                duration: 0,
                glossary: [],
            };
            this.images = []
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            if (this.images.length && !this.images[0].uploaded) {
                this.form.image = this.images[0]
            }
            if (this.video && this.video.id) {
                await videosService.updateVideo(this.video.id, this.form);
            } else {
                if (!this.images.length) {
                    return;
                }
                await videosService.createVideo(this.form);
            }
            this.$nextTick(() => {
                this.resetModal();
                this.$emit('on-close', true);
            })
        },

        onBeforeDeleteImage(fileRecord) {
            const index = this.images.indexOf(fileRecord)
            if (index !== -1) {
                this.images.splice(index, 1)
            }
        },

        async filesSelectedImage(fileRecords) {
            if (this.images.length) {
                for (let i = 0; i < this.images.length; i++) {
                    if (!this.images[i].uploaded && !this.images[i].path) {
                        this.images[i].path = await getBase64(this.images[i].file)
                    }
                }
            }
        },

        addGlossary() {
            this.form.glossary = [...this.form.glossary, {}]
        },

        removeObject(index) {
            this.form.glossary.splice(index, 1)
        },
    },

    computed: {
        title() {
            return this.video != null ? 'Update Video' : 'Add new Video';
        },

        displayDuration() {
            return formattedDuration(this.form.duration * 1000)
        },
    },
}
</script>

<style lang="scss" scoped></style>
<template>
  <router-link disabled
    :to="{ name: isFromAccount ? 'accountPropertyDetail' : 'propertyDetail', params: { uuid: property.uuid } }" :id="`property_item_uuid_${property.uuid}`">
    <div class="property-card light shadow">
      <img :class="{ darken: isSoldOut || isComingSoon }" :src="getAvatar(property.images)" alt="Property image">
      <div class="card-status">
        <p class="sold-out coming-soon" v-if="isComingSoon">{{ $t('PROPERTY_STATUS.COMING_SOON') }}</p>
        <p class="sold-out" v-else-if="isSoldOut">{{ $t('PROPERTY_STATUS.SOLD_OUT') }}</p>
        <p class="promo" v-if="property.status === 'promo' && !isSoldOut">{{ $t('PROPERTY_STATUS.PROMO') }}</p>
        <p class="timer" v-if="property.status === 'promo' && showPromo && !isSoldOut">{{ promoTime }}</p>
        <p class="presale" :class="{ 'presale-full': !showPresale }" v-if="property.status === 'presale' && !isSoldOut">{{
          $t('PROPERTY_STATUS.PRESALE') }}</p>
        <p class="timer" v-if="property.status === 'presale' && showPresale && !isSoldOut">{{ presaleTime }}</p>
      </div>
      <div v-if="!!property.is_new" class="mark-new-property" :class="`lang-${this.$i18n.locale}`"></div>
      <div class="card-info" :sold-out="isSoldOut || null">
        <p class="font-18 card-name m-0" :class="nameClass">{{ property.name }}</p>
        <span class="font-16 font-weight-bold" :class="descriptionClass">{{ property.metadata.address }}</span>
        <p class="card-separator font-14 pt-2 pb-1" :class="descriptionClass">
          <span class="space font-weight-bold">{{ property.num_bed }} {{ $t("Bedrooms") }}</span>
          <span class="space font-weight-bold">{{ property.num_bath }} {{ $t("Bathrooms") }}</span>
          <span class="font-weight-bold">{{ property.sqm }} sqm</span>
        </p>
        <div class="card-separator card-rate-limit d-flex flex-row">
          <div class="d-flex flex-row" :id="'tooltip-target-irr-card' + property.id">
            <div>
              <span class="font-22 font-weight-bold" :class="nameClass">{{ (property.ery || 0) + (property.eca || 0) }}%
                <span class="font-weight-bold m-0">{{ $t("propertyDetail.IRR") }}</span></span>
            </div>
            <div><img class="img-info" src="@/assets/img/info-circle-fill.png" alt=""></div>
          </div>
          <b-tooltip variant="secondary" :target="'tool' +
            'tip-target-irr-card' + property.id" triggers="hover" placement="top">
            {{ $t("propertyDetail.IRR_TOOLTIP") }}
          </b-tooltip>

          <div class="d-flex flex-row ml-5" :id="'tooltip-target-ery-card' + property.id">
            <div>
              <span class="font-22 font-weight-bold" :class="nameClass">{{ property.ery || 0 }}% <span
                  class="font-weight-bold m-0">{{ $t("propertyDetail.ERY") }}</span></span>
            </div>
            <div><img class="img-info" src="@/assets/img/info-circle-fill.png" alt=""></div>
          </div>
          <b-tooltip variant="secondary" :target="'tooltip-target-ery-card' + property.id" triggers="hover"
            placement="top">
            {{ $t("propertyDetail.ERY_TOOLTIP") }}
          </b-tooltip>
        </div>
        <div class="p-progress pt-2">
          <b-progress class="bg-color-progress mb-1" :max="100" :min="0" :precision="2" height="4px">
            <b-progress-bar :class="progressClass" :value="getProgress" />
          </b-progress>
          <span class="pb-2 font-weight-bold" :class="nameClass">{{ getProgress }}%</span>
          <span class="pb-2 font-weight-bold float-right" :class="nameClass">
            {{ property.total_tokens - property.display_sold_tokens }} {{ $t("propertyDetail.token left") }}
          </span>
        </div>
      </div>
      <div :class="{ disabled: isSoldOut || isComingSoon }"
        class="invest-now d-flex justify-content-center align-items-center">
        <p class="font-18 font-weight-bold text">{{ $t("INVEST_NOW") }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
import { formattedDuration, urlImage } from "@/helpers/common"
import moment from "moment"

export default {
  props: {
    property: {},
    isFromAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      promoTime: '',
      presaleTime: '',
    };
  },
  mounted() {
    if ((this.property.status === 'presale' || this.property.status === 'promo') && (this.showPresale || this.showPromo)) {
      const self = this;
      setInterval(() => {
        self.updateTimer();
      }, 1000);
    }
  },
  methods: {
    getAvatar(images) {
      if (images && images.length) {
        return urlImage(images[0])
      }
      return ''
    },
    updateTimer() {
      if (this.property.status === 'presale') {
        const presaleEndsAt = this.property.presale_ends_at;
        const currentDate = new Date();
        const presaleDate = new Date(presaleEndsAt);
        if (currentDate >= presaleDate) {
          this.presaleTime = this.$t('propertyDetail.ENDED');
        } else {
          const diffTime = presaleDate.getTime() - currentDate.getTime();
          this.presaleTime = this.$t('propertyDetail.END_IN', { value: formattedDuration(diffTime) });
        }
      } else if (this.property.status === 'promo') {
        const promoEndsAt = this.property.promo_ends_at;
        const currentDate = new Date();
        const promoDate = new Date(promoEndsAt);
        if (currentDate >= promoDate) {
          this.promoTime = this.$t('propertyDetail.ENDED');
        } else {
          const diffTime = promoDate.getTime() - currentDate.getTime();
          this.promoTime = this.$t('propertyDetail.END_IN', { value: formattedDuration(diffTime) });
        }
      }
    }
  },
  computed: {
    getProgress() {
      if (!this.property.total_tokens) {
        return 0;
      }
      return Math.floor(this.property.display_sold_tokens * 100 / this.property.total_tokens);
    },
    isSoldOut() {
      return this.property.status === "sold" || this.property.display_sold_tokens === this.property.total_tokens
    },
    isComingSoon() {
      return this.property.status === 'coming_soon'
    },
    showPresale() {
      const presaleEndsAt = this.property.presale_ends_at;
      const currentDate = moment(new Date()).add(1, 'days');
      const presaleDate = new Date(presaleEndsAt);
      return currentDate > presaleDate;
    },
    showPromo() {
      const promoEndsAt = this.property.promo_ends_at;
      const currentDate = moment(new Date()).add(1, 'days');
      const promoDate = new Date(promoEndsAt);
      return currentDate > promoDate;
    },
    nameClass() {
      return {
        'color-stock': !this.isSoldOut,
        'color-sold-out': this.isSoldOut,
      };
    },
    descriptionClass() {
      return {
        'color-description': !this.isSoldOut,
        'color-description-sold-out': this.isSoldOut,
      };
    },
    progressClass() {
      return {
        'bg-color-in-progress': !this.isSoldOut,
        'bg-color-sold-out': this.isSoldOut,
      };
    },
  }
};
</script>
<style lang="scss" scoped>
.light{
  &.property-card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: 490px;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    transition: .8s;
    background: var(--primary-light-color);
    padding: 10px;
    justify-content: space-between;
    
    &:hover {
      background: var(--primary-background-color);
      
      img{
        filter: brightness(80%);
        transition: .8s;
      }
    }

    &:hover .card-info {
      background: var(--primary-background-color);
    }

    img {
      width: 100%;
      height: 56%;
      max-height: 237.02px;
      object-fit: cover;
      border: 0 transparent;
      border-radius: 14px;
      background-color: var(--primary-background-color);
    }

    .mark-new-property{
      position: absolute;
      top: 5px;
      right: 5px;
      width: 89px;
      height: 89px;
      background-image: url("~@/assets/img/icons/mark_new_property_en.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      &.lang-id{
        background-image: url("~@/assets/img/icons/mark_new_property_id.png") !important;
      }
    }

    .darken {
      filter: brightness(70%);
    }

    .card-separator {
      &.card-rate-limit{
        padding: 7px 0;
      }
      .space {
        margin-right: 10px;
      }

      .img-info {
        width: 14px;
        height: 14px;
        background-color: transparent;
        z-index: 1;
      }
    }

    .card-info {
      width: 100%;
      transition: .8s;
      background: var(--primary-light-color);
      padding: 0.5rem;

      .card-name {
        font-family: "Figtree-Bold", Helvetica, sans-serif, serif;
        font-weight: 500;
        color: var(--primary-color);
      }

      .color-stock {
        color: var(--primary-color);
      }

      .color-description {
        color: var(--primary-color);
      }

      .color-sold-out {
        color: #769B9D;
      }

      .color-description-sold-out {
        color: #769B9D;
      }

      .bg-color-in-progress {
        background-color: var(--primary-lighter-color);
      }

      .bg-color-sold-out {
        background-color: #769B9D;
      }

      .bg-color-progress {
        background-color: var(--color-in-progress);
      }
    }

    .invest-now {
      border-radius: 12px;
      color: var(--primary-light-color);
      background-color: var(--primary-color);
      text-align: center;
      vertical-align: middle;
      transition: .8s;
      padding: 8px;

      .text {
        color: var(--primary-light-color);
      }
      
      &.disabled {
        background-color: #769B9D;
      }

      &:hover {
        background-color: var(--primary-hover-color);
      }
    }

    h4 {
      margin-top: 0;
      margin-bottom: 0.75rem;
    }

    .card-status {
      position: absolute;
      display: -ms-flexbox;
      display: flex;
      top: 4%;
      left: 4%;
      -ms-flex-direction: row;
      flex-direction: row;

      p {
        padding: 0.5rem 1rem 0.5rem 1rem;
        font-weight: 600;
      }

      .sold-out {
        background-color: var(--primary-color);
        color: white;
        font-size: 13px;
        border-radius: 18px;
        border: white solid 2px;
        padding: 4px 15px 4px 15px;
      }

      .coming-soon {
        background-color: var(--primary-lighter-color);
      }

      .promo {
        background-color: #DC3545;
        color: #ffffff;
        font-size: 13px;
        border-radius: 18px;
        border: white solid 2px;
        padding: 4px 15px 4px 15px;
      }

      .presale {
        background-color: #F7B733;
        color: #ffffff;
        border-radius: 18px 0 0 18px;
        font-size: 13px;
        border: white solid 2px;
        padding: 4px 15px 4px 15px;
      }

      .presale-full {
        border-radius: 18px;
      }

      .timer {
        background-color: #ffffff;
        color: #54595E;
        border-radius: 0 18px 18px 0;
        min-width: 150px;
        font-size: 13px;
        text-align: center;
        padding: 6px 0 4px 0;
      }
    }
  }
}
</style>

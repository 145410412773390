<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button v-if=canAddPlaylist size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="playlists.data" :columns="tableColumns" :customActionTitles="customActions"
                        @onCustomAction="onClickedCustomAction" :show-delete-action="canDeletePlaylist"
                        @onModalDelete="onModalDelete" @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th></th>
                                <th width="8%"></th>
                                <th width="30%">
                                    <b-input v-model="filters.title"></b-input>
                                </th>
                                <th width="40%"></th>
                                <th width="10%"></th>
                                <th width="5%"></th>
                                <th width="5%"></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="playlists.total" align="right" v-model="playlists.current_page"
                        :total-rows="playlists.total" :per-page="playlists.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-create-playlist :show="showModalCreate" :playlist="playlist" @on-close="onModalClose" />
        <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Delete confirm</h5>
                <button id="btn_closeModalDeletePlaylist" type="button" class="close font-weight-bold"
                    aria-label="Close" @click="modalDelete.show = false">×</button>
            </template>
            <p>Are you sure you want to delete this playlist?<strong style="color: red">{{ modalDelete.content
                    }}</strong>
            </p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onDeletePlaylist">
                    Delete
                </b-button>
                <b-button variant="primary" size="sm" @click="modalDelete.show = false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import moment from "moment";
import { PaperTable } from "../../components";
import videosService from "../../services/admin/videos.service";
import ModalCreatePlaylist from "./modals/ModalCreatePlaylist.vue";
import { userHasPermission } from "../../helpers/common";
import { USER_PERMISSIONS } from "../../constants/permissions";

const tableColumns = ["Id", 'Date', "Title", "Description", "Total_Video"];

export default {
    components: {
        PaperTable,
        ModalCreatePlaylist,
    },
    data() {
        return {
            playlists: {},
            tableColumns: [...tableColumns],
            filters: {
                title: '',
            },
            showModalCreate: false,
            playlist: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
        };
    },
    async mounted() {
        await this.getPlaylists(1);
    },
    watch: {
        'filters.title'() {
            this.searchTimeOut(1);
        },
    },
    methods: {

        async getPlaylists(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await videosService.getPlaylists(filters);
            if (result && result.data) {
                result.data = result.data.map(e => {
                    return {
                        ...e,
                        total_video: e.videos_count,
                        date: moment(e.created_at).format('DD/MM/YYYY'),
                    }
                })
                this.playlists = result;
            }
        },

        async onChangePage(page) {
            await this.getPlaylists(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getPlaylists(page);
            }, 400);
        },

        showAddModal() {
            this.playlist = null;
            this.showModalCreate = true;
        },

        onModalClose(success) {
            this.showModalCreate = false;
            if (success) {
                this.getPlaylists(this.playlists.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.playlist = this.playlists.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },

        async onDeletePlaylist() {
            if (this.modalDelete.id) {
                await videosService.deletePlaylist(this.modalDelete.id);
                this.modalDelete = {
                    show: false,
                    content: '',
                    id: null,
                };
                await this.getPlaylists(this.playlists.current_page);
            }
        },

        onClickedCustomAction(data, key) {
            if (key === 'update-playlist') {
                this.playlist = this.playlists.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },
    },

    computed: {
        customActions() {
            if (this.canUpdatePlaylist) {
                return [
                    {
                        label: 'Modify',
                        key: 'update-playlist',
                    }
                ]
            }

            return []
        },

        canAddPlaylist() {
            return userHasPermission(USER_PERMISSIONS.VIDEO.CREATE_VIDEO_PLAYLIST)
        },

        canUpdatePlaylist() {
            return userHasPermission(USER_PERMISSIONS.VIDEO.UPDATE_VIDEO_PLAYLIST)
        },

        canDeletePlaylist() {
            return userHasPermission(USER_PERMISSIONS.VIDEO.DELETE_VIDEO_PLAYLIST)
        },
    },
};
</script>
<style scoped></style>
import serverApi from "../utils/serverApi";

const videosService = {
  getVideos: (data) => serverApi({ uri: "videos", data, method: "GET" }),

  getVideo: async (uuid) => {
    return await serverApi({ uri: `videos/${uuid}`, method: "GET" });
  },

  getVideosFeatured: (data) => serverApi({ uri: "videos/featured", data, method: "GET" }),

  getPlaylists: (data) => serverApi({ uri: "playlists", data, method: "GET" }),

  getPlaylistVideos: async (slug, data) => {
    return await serverApi({ uri: `playlists/${slug}/videos`, data, method: "GET" });
  },
};

export default videosService;

<!--suppress HtmlDeprecatedAttribute, HtmlUnknownTag -->
<template>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="pb-2">
            <b-button size="sm" id="show-btn" @click="onAddNewClicked">
              <b-icon icon="plus-lg"></b-icon>
              Add new
            </b-button>
          </div>
          <div slot="raw-content" class="table-responsive">
            <paper-table :data="rentalDistributionDetails.data" :columns="tableColumns" :showEditAction="true"
                         @onModalEdit="onModalEdit">
              <template #theadSearch>
                <thead class="search">
                <th width="30px"></th>
                <th>
                  <VueDatePicker v-model="filters.date" model-type="yyyy-MM-dd" format="yyyy-MM-dd" style="width: 150px;" />
                </th>
                <th>
                  <b-input v-model="filters.property_name"></b-input>
                </th>
                <th>
                  <b-input v-model="filters.user_email"></b-input>
                </th>
                <th></th>
                <th></th>
                <th>
                  <b-form-select v-model="filters.status" text-field="name" value-field="id">
                    <template #first>
                      <b-form-select-option :value="null">Select status</b-form-select-option>
                    </template>
                    <template v-for="status in statuses">
                      <b-form-select-option :value="status">
                        {{ status }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </th>
                <th></th>
                </thead>
              </template>
            </paper-table>
            <b-pagination v-if="rentalDistributionDetails.total" align="right"
                          v-model="rentalDistributionDetails.current_page"
                          :total-rows="rentalDistributionDetails.total" :per-page="rentalDistributionDetails.per_page"
                          @change="onChangePage" aria-controls="my-table"></b-pagination>
          </div>
        </div>
      </div>
      <modal-create-rental-distribution-detail :show="showModalCreate" @on-create-close="onModalCreateClose" :isVirtual="true"/>
      <modal-rental-distribution-detail :show="showModal" :rentalDistributionDetail="rentalDistributionDetail"
                                        @on-close="onModalEditClose"/>
    </div>
  </template>
  <script>
  import { PaperTable } from "../../components"
  import ModalRentalDistributionDetail from "@/pages/admin/rental-distribution/modals/ModalRentalDistributionDetail.vue"
  import ModalCreateRentalDistributionDetail from "@/pages/admin/rental-distribution/modals/ModalCreateRentalDistributionDetail.vue"
  import virtualRentalDistributionService from "../../services/admin/virtualRentalDistribution.service"
  import { STATUS } from "@/constants/constants"

  const tableColumns = ["Id", "Date", "Property_Name", "User_Email", "Num_Of_Tokens", "Amount", "Status"]

  export default {
    components: {
      PaperTable,
      ModalRentalDistributionDetail,
      ModalCreateRentalDistributionDetail,
    },
    data () {
      return {
        rentalDistributionDetails: {},
        tableColumns: [...tableColumns],
        filters: {
          date: "",
          property_id: "",
          property_name: "",
          user_email: "",
          status: "",
        },
        showModal: false,
        showModalCreate: false,
        rentalDistributionDetail: null,
        statuses: [STATUS.WAITING, STATUS.PENDING, STATUS.APPROVED],
      }
    },
    async mounted () {
      await this.getRentalDistributionDetails(1)
    },
    watch: {
      "filters.date" () {
        this.searchTimeOut(1)
      },
      "filters.property_id" () {
        this.searchTimeOut(1)
      },
      "filters.property_name" () {
        this.searchTimeOut(1)
      },
      "filters.user_email" () {
        this.searchTimeOut(1)
      },
      "filters.status" () {
        this.searchTimeOut(1)
      }
    },
    methods: {
      async onAddNewClicked () {
        this.showModalCreate = true
      },

      async getRentalDistributionDetails (page) {
        const filters = {
          ...this.filters,
          page,
        }
        const result = await virtualRentalDistributionService.getRentalDistributionDetails(filters)
        if (result && result.data) {
          this.rentalDistributionDetails = result
        }
      },

      async onChangePage (page) {
        await this.getRentalDistributionDetails(page)
      },

      searchTimeOut (page) {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(async () => {
          await this.getRentalDistributionDetails(page)
        }, 400)
      },

      async onModalEdit (data) {
        if (data) {
          this.rentalDistributionDetail = this.rentalDistributionDetails.data.find((e) => e.id === data)
          this.showModal = true
        }
      },

      onModalEditClose () {
        this.rentalDistributionDetail = null
        this.showModal = false
      },

      async onModalCreateClose (updated) {
        this.showModalCreate = false
        if (updated) {
          await this.getRentalDistributionDetails(this.rentalDistributionDetails.current_page)
        }
      },
    },
  }
  </script>
  <style scoped>
  </style>

<!--suppress HtmlDeprecatedAttribute, HtmlUnknownTag -->
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between pb-2">
          <b-button size="sm" id="show-btn" @click="onAddNewClicked">
            <b-icon icon="plus-lg"></b-icon>
            Add new
          </b-button>
          <b-button size="sm" id="show-btn" @click="showSendingModal = true">
            Start Sending Rental Distributions
          </b-button>
        </div>
        <div slot="raw-content" class="table-responsive">
          <paper-table :data="rentalDistributions.data" :columns="tableColumns" :showEditAction="true"
                       @onModalEdit="onModalEdit">
            <template #theadSearch>
              <thead class="search">
              <th width="30px"></th>
              <th>
                <VueDatePicker v-model="filters.date" month-picker model-type="dd-MM-yyyy" format="MM-yyyy" :max-date="max" />
              </th>
              <th width="12%">
                <b-input v-model="filters.property_id"></b-input>
              </th>
              <th>
                <b-input v-model="filters.property_name"></b-input>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <b-form-select v-model="filters.status" text-field="name" value-field="id">
                  <template #first>
                    <b-form-select-option :value="null">Select status</b-form-select-option>
                  </template>
                  <template v-for="status in statuses">
                    <b-form-select-option :value="status">
                      {{ status }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th></th>
              </thead>
            </template>
            <template v-slot:cell-status="{ item }">
              <p>{{ item.status }}
                <b-icon v-if="item.status === STATUS.APPROVED" icon="check-circle-fill"
                        variant="success" style="margin-bottom: 1px; margin-left: 1px"/>
                <b-icon v-if="item.status === STATUS.REJECTED" icon="exclamation-circle-fill"
                        variant="danger" style="margin-bottom: 1px; margin-left: 1px"/>
              </p>
            </template>
          </paper-table>
          <b-pagination v-if="rentalDistributions.total" align="right" v-model="rentalDistributions.current_page"
                        :total-rows="rentalDistributions.total" :per-page="rentalDistributions.per_page"
                        @change="onChangePage" aria-controls="my-table"></b-pagination>
        </div>
      </div>
    </div>
    <modal-rental-distribution :show="showDetailModal" :rentalDistribution="rentalDistribution" @on-close="onModalClose"/>
    <modal-start-rental-distributions :show="showSendingModal" @on-close="showSendingModal = false"/>
  </div>
</template>
<script>
import { PaperTable } from "../../../components"
import { STATUS } from "@/constants/constants"
import ModalRentalDistribution from "@/pages/admin/rental-distribution/modals/ModalRentalDistribution.vue"
import ModalStartRentalDistributions from "@/pages/admin/rental-distribution/modals/ModalStartRentalDistributions.vue"
import rentalDistributionService from "../../../services/admin/rentalDistribution.service"
import moment from "moment"

const tableColumns = ["Id", "Date", "Property_Id", "Property_Name", "Rental_Income", "Occupancy_Rate", "Description", "Status"]

export default {
  components: {
    PaperTable,
    ModalRentalDistribution,
    ModalStartRentalDistributions
  },
  data () {
    return {
      rentalDistributions: {},
      tableColumns: [...tableColumns],
      filters: {
        date: "",
        property_id: "",
        property_name: "",
        status: "",
      },
      showDetailModal: false,
      showSendingModal: false,
      rentalDistribution: null,
      statuses: [STATUS.PENDING, STATUS.APPROVED, STATUS.REJECTED],
      monthLabels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    }
  },
  async mounted () {
    await this.getRentalDistributions(1)
  },
  computed: {
    max() {
      return moment().subtract(1, "months").toDate()
    },
    STATUS() {
      return STATUS
    },
  },
  watch: {
    "filters.date" () {
      this.searchTimeOut(1)
    },
    "filters.property_id" () {
      this.searchTimeOut(1)
    },
    "filters.property_name" () {
      this.searchTimeOut(1)
    },
    "filters.status" () {
      this.searchTimeOut(1)
    }
  },
  methods: {
    async onAddNewClicked () {
      await this.$router.push({ name: "adminProperties" })
    },

    async getRentalDistributions (page) {
      const filters = {
        ...this.filters,
        page,
      }
      const result = await rentalDistributionService.getRentalDistributions(filters)
      if (result && result.data) {
        this.rentalDistributions = result
        this.rentalDistributions.data.forEach(data => data.date = moment(data.date).format("MM-YYYY"))
      }
    },

    async onChangePage (page) {
      await this.getRentalDistributions(page)
    },

    searchTimeOut (page) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        await this.getRentalDistributions(page)
      }, 400)
    },

    async onModalEdit (data) {
      if (data) {
        this.rentalDistribution = this.rentalDistributions.data.find((e) => e.id === data)
        this.showDetailModal = true
      }
    },

    onModalClose (needUpdate) {
      this.rentalDistribution = null
      this.showDetailModal = false
      if (needUpdate) {
        this.getRentalDistributions(this.rentalDistributions.current_page)
      }
    },
  },
}
</script>
<style scoped>
</style>

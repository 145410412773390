export default {
  geoLocation: state => state.geoLocation,
  stayAtCurrentLocation: state => state.stayAtCurrentLocation,
  switchToNewLocation: state => state.switchToNewLocation,
  userProfile: state => state.userProfile,
  isLoading: state => state.isLoading,
  unread: state => state.unread,
  availableBanks: state => state.availableBanks,
  configs: state => state.configs,
  referralCode: state => state.referralCode,
  assetsCount: state => state.assetsCount,
  pendingTasksCount: state => state.pendingTasksCount,
  initialBalance: state => state.initialBalance,
  exchangeRates: state => state.exchangeRates,
  socialLoginData: state => state.socialLoginData,
  adminPermissions: state => state.adminPermissions,
  required2FA: state => state.required2FA,
  partnerPermissions: state => state.partnerPermissions,
  goLearnPlaylists:  state => state.goLearnPlaylists,
};

import serverApi from "../../utils/serverApi"

const rentalDistributionService = {
  getRentalDistributions: (data) => serverApi({
    uri: "admin/rental-distributions", method: "GET", data
  }),

  getRentalDistributionInfo: (data, isManualErrorHandling = false) => serverApi({
    uri: "admin/rental-distributions/info", method: "GET", data, isManualErrorHandling: isManualErrorHandling
  }),

  createRentalDistribution: (body) => serverApi({
    uri: "admin/rental-distributions/create",
    method: "POST",
    data: body
  }),

  updateRentalDistribution: (body) => serverApi({
    uri: "admin/rental-distributions/update",
    method: "PUT",
    data: body
  }),

  startDistributions: (body) => serverApi({
    uri: "admin/rental-distributions/start-distributions",
    method: "POST",
    data: body
  }),

  getRentalDeductions: (data) => serverApi({
    uri: "admin/rental-distributions/deductions", method: "GET", data
  }),

  getRentalDeductionTypes: (data) => serverApi({
    uri: "admin/rental-distributions/deduction-types", method: "GET", data
  }),

  createRentalDeductionType: (body) => serverApi({
    uri: "admin/rental-distributions/create-deduction-type",
    method: "POST",
    data: body
  }),

  updateRentalDeductionType: (body) => serverApi({
    uri: "admin/rental-distributions/update-deduction-type",
    method: "PUT",
    data: body
  }),

  deleteRentalDeductionType: (id) => serverApi({
    uri: `admin/rental-distributions/delete-deduction-type/${id}`,
    method: "DELETE",
  }),

  createRentalDistributionDetails: (body) => serverApi({
    uri: "admin/rental-distributions/create-details",
    method: "POST",
    data: body
  }),

  getRentalDistributionDetails: (data) => serverApi({
    uri: "admin/rental-distributions/details", method: "GET", data
  }),
}

export default rentalDistributionService

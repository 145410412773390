<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button v-if="canAddVideo" size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="videos.data" :columns="tableColumns" :imageColumns="imageColumns"
                        :htmlColumns="htmlColumns" :customActionTitles="customActions"
                        @onCustomAction="onClickedCustomAction" :show-delete-action="canDeleteVideo"
                        @onModalDelete="onModalDelete" @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th width="5%"></th>
                                <th width="15%"></th>
                                <th width="40%">
                                    <b-input v-model="filters.keyword"></b-input>
                                </th>
                                <th width="10%">
                                    <b-form-select v-model="filters.playlist_id" text-field="name" value-field="id">
                                        <template #first>
                                            <b-form-select-option :value="null">Select playlist</b-form-select-option>
                                        </template>
                                        <template v-for="playlist in playlists">
                                            <b-form-select-option :value="playlist.id">
                                                {{ playlist.title }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </th>
                                <th width="5%"></th>
                                <th width="5%"></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="videos.total" align="right" v-model="videos.current_page"
                        :total-rows="videos.total" :per-page="videos.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-create-video :show="showModalCreate" :video="video" @on-close="onModalClose" />
        <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Delete confirm</h5>
                <button id="btn_closeModalDeleteVideo" type="button" class="close font-weight-bold" aria-label="Close"
                    @click="modalDelete.show = false">×</button>
            </template>
            <p>Are you sure you want to delete this video?<strong style="color: red">{{ modalDelete.content
                    }}</strong>
            </p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onDeleteVideo">
                    Delete
                </b-button>
                <b-button variant="primary" size="sm" @click="modalDelete.show = false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import videosService from "../../services/admin/videos.service";
import ModalCreateVideo from "./modals/ModalCreateVideo.vue";
import moment from "moment";
import { urlImage, getImage } from "../../helpers/common";
import { userHasPermission } from "../../helpers/common";
import { USER_PERMISSIONS } from "../../constants/permissions";

const tableColumns = ["Id", "Date", "Thumbnail", "Title_And_Description", "Playlist", "Featured_Content", "Weight"];

export default {
    components: {
        PaperTable,
        ModalCreateVideo,
    },
    data() {
        return {
            videos: {},
            playlists: [],
            tableColumns: [...tableColumns],
            imageColumns: [2],
            htmlColumns: [3, 5],
            filters: {
                keyword: '',
                playlist_id: null,
            },
            showModalCreate: false,
            video: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
        };
    },
    async mounted() {
        await this.getPlaylists();
        await this.getVideos(1);
    },
    watch: {
        'filters.keyword'() {
            this.searchTimeOut(1);
        },
        'filters.playlist_id'() {
            this.searchTimeOut(1);
        },
    },
    methods: {

        async getVideos(page) {
            const filters = {
                ...this.filters,
                page,
            };
            let result = await videosService.getVideos(filters);
            if (result && result.data) {
                result.data = result.data.map(e => {
                    return {
                        ...e,
                        date: moment(e.created_at).format('DD/MM/YYYY'),
                        thumbnail: urlImage({ image: e.thumb }),
                        playlist: e.playlist ? e.playlist.title : 'N/A',
                        featured_content: `
                            <img src="${getImage(e.is_featured ? 'checkbox-checked' : 'checkbox-unchecked')}" alt="" width="16" height="16">
                        `,
                        title_and_description: `
                        <div>
                            <div class="d-flex flex-row align-items-center">
                                <strong>${e.title}</strong>
                                <div class="video-text-lang ml-2">${e.lang}</div>
                            </div>
                            <p class="text-description">${e.description}</p>
                        </div>
                        `,
                    }
                })
                this.videos = result;
            }
        },

        async getPlaylists() {
            const result = await videosService.getPlaylists({
                page: 1,
                per_page: 1000,
            });
            if (result && result.data) {
                this.playlists = result.data;
            }
        },

        async onChangePage(page) {
            await this.getVideos(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getVideos(page);
            }, 400);
        },

        showAddModal() {
            this.video = null;
            this.showModalCreate = true;
        },

        onModalClose(success) {
            this.showModalCreate = false;
            if (success) {
                this.getVideos(this.videos.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.video = this.videos.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },

        async onDeleteVideo() {
            if (this.modalDelete.id) {
                await videosService.deleteVideo(this.modalDelete.id);
                this.modalDelete = {
                    show: false,
                    content: '',
                    id: null,
                };
                await this.getVideos(this.videos.current_page);
            }
        },

        onClickedCustomAction(data, key) {
            if (key === 'update-video') {
                this.video = this.videos.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },
    },

    computed: {

        customActions() {
            if (this.canUpdateVideo) {
                return [
                    {
                        label: 'Modify',
                        key: 'update-video',
                    }
                ]
            }

            return []
        },
        canAddVideo() {
            return userHasPermission(USER_PERMISSIONS.VIDEO.CREATE_VIDEO)
        },

        canUpdateVideo() {
            return userHasPermission(USER_PERMISSIONS.VIDEO.UPDATE_VIDEO)
        },

        canDeleteVideo() {
            return userHasPermission(USER_PERMISSIONS.VIDEO.DELETE_VIDEO)
        },
    },
};
</script>
<style>
.video-text-lang {
    padding: 4px 16px;
    background-color: #000;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.text-description {
    font-size: 13px;
}
</style>